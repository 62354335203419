import { call, put, takeLatest } from "redux-saga/effects";
import { setBotTyping, ADD_MESSAGE, SET_SESSIONS, SET_MESSAGES } from "./actions";

// API Functions
const fetchSessionsAPI = async () => {
  const token = localStorage.getItem("token");
  const response = await fetch("https://app.live-metrics.io/sessions", {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
  });
  if (!response.ok) throw new Error("Failed to fetch sessions");
  const data = await response.json();
  return data.sessions;
};

const fetchMessagesAPI = async (sessionId) => {
  const response = await fetch(`https://app.live-metrics.io/messages/${sessionId}`);
  if (!response.ok) throw new Error("Failed to fetch messages");
  const data = await response.json();
  return data.messages.map((msg) => ({ type: msg.sender, text: msg.message }));
};

const sendMessageAPI = async (message, sessionId) => {
  const token = localStorage.getItem("token");
  const response = await fetch("https://app.live-metrics.io/chat", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
    body: JSON.stringify({
      query: message,
      session_id: sessionId || "default_session",
      platform: "facebook",
    }),
  });

  if (!response.ok) throw new Error("Failed to send message");
  return response.json();
};

// Worker Saga: Fetch Sessions
function* fetchSessionsSaga() {
  try {
    const sessions = yield call(fetchSessionsAPI);
    yield put({ type: SET_SESSIONS, payload: sessions });
  } catch (error) {
    console.error("Error fetching sessions:", error);
  }
}

// Worker Saga: Fetch Messages
function* fetchMessagesSaga(action) {
  try {
    const messages = yield call(fetchMessagesAPI, action.payload);
    yield put({ type: SET_MESSAGES, payload: messages });
  } catch (error) {
    console.error("Error fetching messages:", error);
  }
}

// Worker Saga: Send Message
function* sendMessageSaga(action) {
  try {
    const { message, sessionId, platform, history } = action.payload;
    yield put(setBotTyping(true));

    // Send user's message
    yield put({ type: ADD_MESSAGE, payload: { type: "user", text: message } });

    // Call the backend API
    const data = yield call(sendMessageAPI, message, sessionId);

    // Add the bot's response
    yield put({ type: ADD_MESSAGE, payload: { type: "bot", text: data.response } });

    yield put(setBotTyping(false));
    if (data) {
      history.push(`/advisor/${data.session_id}`);
    }
  } catch (error) {
    console.error("Error in sendMessageSaga:", error);
    yield put(setBotTyping(false));
  }
}

// Watcher Saga
export default function* AdvisorSaga() {
  yield takeLatest("FETCH_SESSIONS", fetchSessionsSaga);
  yield takeLatest("FETCH_MESSAGES", fetchMessagesSaga);
  yield takeLatest("SEND_MESSAGE", sendMessageSaga);
}

