import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchMessages, sendMessage } from "../../store/AdvisorState/actions";
import { Spin, Tooltip } from "antd";
import RenderMessage from "./RenderMessage";
import chatboticon from "../../assets/images/chatbot-icon.png";
import MessageInputBar from "./MessageInputBar"; // Import the new component

const Session = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [typingMessage, setTypingMessage] = useState("");
  const [currentBotMessage, setCurrentBotMessage] = useState(null);
  const { messages, loadingMessages, botTyping } = useSelector((state) => state.AdvisorReducer);
  const { sessionId } = useParams();
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);

  // Fetch messages when sessionId changes
  useEffect(() => {
    if (sessionId) {
      dispatch(fetchMessages(sessionId));
    }
  }, [sessionId, dispatch]);

  // Scroll to bottom when messages update
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  // Simulate chatbot typing effect
  useEffect(() => {
    if (currentBotMessage) {
      let index = 0;
      setTypingMessage("");

      const interval = setInterval(() => {
        setTypingMessage((prev) => prev + currentBotMessage.charAt(index));
        index++;

        if (index >= currentBotMessage.length) {
          clearInterval(interval);
          setCurrentBotMessage(null);
        }
      }, 50);
    }
  }, [currentBotMessage]);

  // Handle sending a message
  const handleSendMessage = () => {
    if (message.trim()) {
      dispatch(sendMessage(message, sessionId));
      setMessage("");
    }
  };

  return (
    <div className="page-content" style={{ padding: "20px 0", height: "100vh", display: "flex", flexDirection: "column" }}>
      <Container fluid className="d-flex flex-column flex-grow-1">
        <Row className="flex-grow-1">
          <Col>
            <Spin spinning={loadingMessages} tip="Loading messages...">
              <div
                ref={messagesContainerRef}
                style={{
                  overflowY: "auto",
                  maxHeight: "85vh", 
                 
                }}
              >
                <ul className="list-unstyled" style={{ padding: "20px 209px " ,marginTop:'50px'}}>
                  {messages.map((msg, index) => (
                    <li
                      key={`${msg.type}-${index}`}
                      className={`d-flex ${msg.type === "user" ? "justify-content-end" : "justify-content-start align-items-start"} mb-3`}
                    >
                      {msg.type !== "user" && <img src={chatboticon} alt="Chatbot Icon" style={{ marginTop: "13px" }} />}
                      <div
                        className={`${msg.type === "user" ? "bg-user-message" : "text-dark rounded"}`}
                        style={{
                          fontSize: "15px",
                          maxWidth: "80%",
                          lineHeight: "2",
                        }}
                      >
                             
                        <RenderMessage msg={msg} />
                      </div>
                    </li>
                  ))}
                  {botTyping && (
                    <li className="d-flex justify-content-start align-items-start mb-3">
                      <div style={{ marginRight: "10px" }}>
                        <img src={chatboticon} alt="Chatbot Icon" />
                      </div>
                      <div class="chat-bubble">
                      <div class="typing">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                      </div>
                      </div>
                      
                    </li>
                  )}
                  {currentBotMessage && (
                    <li className="d-flex justify-content-start align-items-start mb-3">
                      <img src={chatboticon} alt="Chatbot Icon" />
                      <div
                        className="text-dark"
                        style={{
                          fontSize: "14px",
                          maxWidth: "80%",
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                          lineHeight: "1.8",
                        }}
                      >
                        {typingMessage}
                      </div>
                    </li>
                  )}
                  <div ref={messagesEndRef} />
                </ul>
              </div>
            </Spin>
          </Col>
        </Row>

        <MessageInputBar
          message={message}
          setMessage={setMessage}
          handleSendMessage={handleSendMessage}
          botTyping={botTyping}
        />
      </Container>
    </div>
  );
};

export default Session;
