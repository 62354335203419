import React, { useEffect, useState } from "react";
import { Button, Container, Input, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { SyncOutlined, SendOutlined,PauseCircleFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import { sendMessage, fetchSessions } from "../../store/AdvisorState/actions";
import liveAdvisor from "../../assets/images/liveAdvisor.png";
import { useHistory } from "react-router-dom";

const GoogleAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/GoogleADS.svg`;
const FacebookAdsIcon = `${process.env.PUBLIC_URL}/images/integrations_images/AD8.png`;

const Chat = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const {botTyping} = useSelector((state) => state.AdvisorReducer);
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchSessions());
  }, [dispatch]);

  const handleSendMessage = () => {
    if (message.trim()) {
      dispatch(sendMessage(message, "default_session", history));
      setMessage("");
    }
  };

  return (
    <div className="page-content">
      <Container
        fluid
        className="d-flex flex-column justify-content-center"
        style={{ height: "65vh", overflow: "hidden", width: "65%" }}
      >
        {/* Loading Indicator */}
        {botTyping ? (
         <Row>
         <Col className="text-center my-3">
           <img src={liveAdvisor} alt="LiveAdvisor" style={{ width: "200px" }} />
         </Col>
       </Row>
        ) : (
          <>
            <Row>
              <Col className="text-center my-3">
                <img src={liveAdvisor} alt="LiveAdvisor" style={{ width: "200px" }} />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="6" lg="5" className="text-center">
                <div className="mb-4">
                  <img src={GoogleAdsIcon} alt="Google Ads" style={{ width: "40px", marginBottom: "10px" }}   onChange={(e) => setMessage(e.target.value)} />
                  <h5>Google Ads</h5>
                  <div>
                    <p>
                      <Button color="link" style={{ color: "#000", padding: "0", fontSize: "1rem" }}   onChange={(e) => setMessage(e.target.value)}>
                        What is Google Ads, and how does it work?
                      </Button>
                    </p>
                    <p>
                      <Button color="link" style={{ color: "#000", padding: "0", fontSize: "1rem" }}   onChange={(e) => setMessage(e.target.value)}>
                        How much does it cost to run a Google Ads campaign?
                      </Button>
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="6" lg="5" className="text-center">
                <div className="mb-4">
                  <img src={FacebookAdsIcon} alt="Facebook Ads" style={{ width: "40px", marginBottom: "10px" }}   onChange={(e) => setMessage(e.target.value)} />
                  <h5>Facebook Ads</h5>
                  <div>
                    <p>
                      <Button color="link" style={{ color: "#000", padding: "0", fontSize: "1rem" }}   onChange={(e) => setMessage(e.target.value)}>
                        How do I create an effective Meta Ads campaign?
                      </Button>
                    </p>
                    <p>
                      <Button color="link" style={{ color: "#000", padding: "0", fontSize: "1rem" }}   onChange={(e) => setMessage(e.target.value)}>
                        How do I choose the right audience for my Meta Ads?
                      </Button>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )}
              <Row className="mt-3">
        <Col>
          <div
            className="d-flex align-items-center"
            style={{
              backgroundColor: "#f2f2fc",
              borderRadius: "25px",
              padding: "10px 20px",
              position: "sticky",
              bottom: 0,
            }}
          >
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type message"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }} 
              className="flex-grow-1 border-0 custom-input"
              style={{
                backgroundColor: "transparent",
                height: "40px",
                color: "#5a5a5a",
                outline: "none",
                fontSize: "1rem",
              }}
            />

          
      <Button
        color="transparent"
        className="p-0 ms-3"
        onClick={handleSendMessage}
        disabled={botTyping}
        style={{
          border: "none",
          padding: "0",
          marginLeft: "12px",
          background: "transparent",
        
        }}
      >
        {botTyping ? (
          <Tooltip title="pause">
            <PauseCircleFilled style={{ fontSize: "1.7rem", color: "#0080F9" }}/>
          </Tooltip>
        ) : (
        <Tooltip title="Send"> 
          <SendOutlined style={{ fontSize: "1.7rem", color: "#0080F9" }} />
          </Tooltip>
        )}
      </Button>
          </div>
        </Col>
      </Row>
      </Container>
    </div>
  );
};

export default Chat;
