import { combineReducers } from 'redux';
// Front
import Layout from './layout/reducer';
import aramex from './aramexState/reducer';
//prestashop
import prestashop from './prestaShop/reducers';
//woocommerce
import woocommerce from './woocommerce/reducers';
//klaviyo
import klaviyo from './Klaviyo/reducer';
//editprofile
import editProfile from './auth/reducer';
//member
import member from './Member/reducer';
//Google Analytics
import googleAnalytics from './googleAnalytics/reducers';
// report operation
import operation from './reports/operations/reducer';
// report strategic
import strategic from './reports/strategic/reducer';
// report overview
import overview from './reports/overview/reducer';

//google ads
import GoogleAds from './googleAds/reducer';
// facebook ads
import facebookAds from './facebookAds/reducer';
// instagram
import Instagram from './instagram/reducer';

import dateRangeReducer from './calenderState/reducer';

//facebook page
import facebookPage from './facebookPage/reducer';

import CurrencyReducer from './currencyState/reducer';

//chatbot advisor
import AdvisorReducer from './AdvisorState/reducer';


const rootReducer = combineReducers({
  // public
  Layout,
  aramex,
  prestashop,
  woocommerce,
  klaviyo,
  editProfile,
  member,
  googleAnalytics,
  operation,
  strategic,
  GoogleAds,
  facebookAds,
  overview,
  Instagram,
  dateRangeReducer,
  facebookPage,
  CurrencyReducer,
  AdvisorReducer
});
export default rootReducer;
