// reducer.js

import {
    SET_SESSIONS,
    SET_MESSAGES,
    ADD_MESSAGE,
    CLEAR_MESSAGES
  } from "./actions";
  
  const initialState = {
    sessions: [],
    messages: [],
    loading: true,
    loadingMessages: true,
    botTyping: false, // Add this
  };
  
  export default function AdvisorReducer(state = initialState, action) {
    switch (action.type) {
      case "SET_SESSIONS":
        return { ...state, sessions: action.payload, loading: false };
  
      case "SET_MESSAGES":
        return { ...state, messages: action.payload, loadingMessages: false };
  
      case "ADD_MESSAGE":
        return { ...state, messages: [...state.messages, action.payload] };
  
      case "CLEAR_MESSAGES":
        return { ...state, messages: [] };
  
        case "BOT_TYPING":
          return { ...state, botTyping: action.payload };
  
      default:
        return state;
    }
  }