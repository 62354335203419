import { Button, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { FileSearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { fetchSessions ,clearMessages} from "../../store/AdvisorState/actions";
import { useHistory } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

const SideBarAdvisorContent = () => {
  const dispatch = useDispatch();
  const history = useHistory(); // For navigation
  const { sessions, loading } = useSelector((state) => state.AdvisorReducer); // Get sessions and loading state from Redux
  const [content, setContent] = useState([]);

  useEffect(() => {
    dispatch(fetchSessions());
  }, [dispatch]);
  

  useEffect(() => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    const last7Days = new Date();
    last7Days.setDate(today.getDate() - 7);
  
    const processedContent = [
      {
        date: 'Today',
        sessions: sessions
          .filter(session => {
            const sessionDate = new Date(session.started_at);
            return sessionDate.toDateString() === today.toDateString();
          })
          .map(session => ({ session_name: session.session_name, session_id: session.session_id })),
      },
      {
        date: 'Yesterday',
        sessions: sessions
          .filter(session => {
            const sessionDate = new Date(session.started_at);
            return sessionDate.toDateString() === yesterday.toDateString();
          })
          .map(session => ({ session_name: session.session_name, session_id: session.session_id })),
      },
      {
        date: 'Previous 7 Days',
        sessions: sessions
          .filter(session => {
            const sessionDate = new Date(session.started_at);
            return (
              sessionDate >= last7Days &&
              sessionDate < yesterday // Exclude yesterday
            );
          })
          .map(session => ({ session_name: session.session_name, session_id: session.session_id })),
      },
      {
        date: 'Older',
        sessions: sessions
          .filter(session => {
            const sessionDate = new Date(session.started_at);
            return sessionDate < last7Days;
          })
          .map(session => ({ session_name: session.session_name, session_id: session.session_id })),
      },
    ].filter(item => item.sessions.length > 0); // Exclude empty sections
  
    setContent(processedContent);
  }, [sessions]);

  return (
    <SimpleBar className="p-3" style={{ maxHeight: '100%' }}>
      <Button
          onClick={() => {
             history.push('/advisor');
             dispatch(clearMessages());
          
        }}
        className="mb-5"
        type="default"
        icon={<FileSearchOutlined />}
        block
      >
        Create New Chat
      </Button>
     
      <Spin spinning={loading} tip="Loading Sessions...">
        <div>
          {content.map(item => (
            <div key={item.date} className="mb-5">
              <span className="maintitle mb-1 ms-3">{item.date}</span>
              <div className="session-buttons ms-3">
                {item.sessions.map((session, index) => (
                
                  <Button
                    key={index}
                    type="text"
                    className="subtitle no-hover"
                    style={{ display: 'block', textAlign: 'left' }} // Each session on a new line
                    onClick={() => {
                      if (session.session_id) {
                        history.push(`/advisor/${session.session_id}`);
                      }
                    }}
                  >
                    {session.session_name}
                  </Button>
                
                ))}
              </div>
            </div>
          ))}
        </div>
      </Spin>
      
    </SimpleBar>
  );
}

export default SideBarAdvisorContent;
