import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { withTranslation } from 'react-i18next';
// Import menuDropdown
import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';
import whitelogo from '../../assets/images/Live Metrics White Logo.png';
import whiteicon from '../../assets/images/White-logo_LiveMetrics.svg';
import ToggleIconB from '../../assets/images/ToggleIcon_Black.svg';
import ToggleIconW from '../../assets/images/ToggleIcon_White.svg';
import falarge from '../../assets/images/fa-th.png';

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  changelayoutMode,
} from '../../store/actions';

const HeaderAdvisor = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [isClick, setClick] = useState(true);
  const [iconColor, setIconColor] = useState('white');
  const [selectedLabel, setSelectedLabel] = useState('Facebook'); // Default to Facebook Ads

  const items = [
    { key: 'Google', label: 'Google Ads', value: 'Google' },
    { key: 'Facebook', label: 'Facebook Ads', value: 'Facebook' },
  ];

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   if (!searchParams.has('platform')) {
  //     searchParams.set('platform', 'facebook'); // Set default platform
  //     history.replace(`${location.pathname}?${searchParams.toString()}`); // Replace current URL without reloading
  //   } else {
  //     const defaultItem = items.find((item) => item.value === searchParams.get('platform'));
  //     if (defaultItem) {
  //       setSelectedLabel(defaultItem.label); // Update label based on current URL
  //     }
  //   }
  // }, [location, history]);

  /*** Sidebar menu icon and default menu set */
  const tToggle = () => {
    const body = document.body;
    setClick(!isClick);
    setIconColor(isClick ? 'gray' : 'white'); // Toggle color
    if (isClick) {
      body.classList.add('sidebar-enable');
      body.setAttribute('data-sidebar-size', 'sm');
    } else {
      body.classList.remove('sidebar-enable');
      body.setAttribute('data-sidebar-size', 'lg');
    }
  };

  const handleMenuClick = ({ key }) => {
    const selectedItem = items.find((item) => item.key === key);
    if (selectedItem) {
      setSelectedLabel(selectedItem.label); // Update label
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('platform', selectedItem.value); // Update URL with selected platform
      history.push(`${location.pathname}?${searchParams.toString()}`);
    }
  };

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/overview-dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={whiteicon} alt="" height="27" />
                </span>
                <span className="logo-lg">
                  <img src={whitelogo} alt="" height="27" />
                </span>
              </Link>

              <Link to="/prestashop-dashboard" className="logo logo-light">
                <span className="logo-sm">
                  <img src={whiteicon} alt="" height="25" />
                </span>
                <span className="logo-lg">
                  <img src={whitelogo} alt="" height="27" />
                </span>
              </Link>
            </div>

            <button
              onClick={tToggle}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              {isClick ? (
                <img src={ToggleIconW} alt="" height="14" />
              ) : (
                <img src={ToggleIconB} alt="" height="14" />
              )}
            </button>

            <Dropdown
              className="mt-2 text-dark "
              menu={{
                items,
                onClick: handleMenuClick,
              }}
              trigger={['click']}
            >
              <Space style={{ cursor: 'pointer', marginLeft:"30px" ,fontWeight:"bold"}}>
                {selectedLabel} <DownOutlined />
              </Space>
            </Dropdown>
          </div>

          <div className="d-flex align-items-center" style={{ marginRight: '23px' }}>
            <Button
              style={{
                backgroundColor: '#04044C',
                margin: '0 15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '40px',
                minHeight: '40px',
                padding: '0 15px',
                color: 'white',
                borderRadius: '5px',
                fontSize: '14px',
              }}
            >
              <img src={falarge} style={{ marginRight: '6px' }} alt="icon" />
              Livemetrics Dashboard
            </Button>

            <ProfileMenu />
            <LanguageDropdown />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

HeaderAdvisor.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  changelayoutMode: PropTypes.func,
  layoutMode: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, layoutMode } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, layoutMode };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  changelayoutMode,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(HeaderAdvisor));
