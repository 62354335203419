import React from "react";
import { Button, Tooltip } from "antd";
import { SendOutlined, SyncOutlined ,PauseCircleFilled} from "@ant-design/icons";

const MessageInputBar = ({ message, setMessage, handleSendMessage, botTyping }) => {
  return (
    <div
      className="d-flex align-items-center"
      style={{
        backgroundColor: "#f2f2fc",
        borderRadius: "25px",
        padding: "10px 20px",
        position: "fixed",
        bottom: "45px",
        left: "58%",
        transform: "translateX(-50%)",
        width: "60%",
      }}
    >
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type message"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSendMessage();
          }
        }}
        className="flex-grow-1 border-0 custom-input"
        style={{
          backgroundColor: "transparent",
          height: "40px",
          color: "#5a5a5a",
          outline: "none",
          fontSize: "1rem",
        }}
      />

      <Button
        color="transparent"
        className="p-0 ms-3"
        onClick={handleSendMessage}
        disabled={botTyping}
        style={{
          border: "none",
          padding: "0",
          marginLeft: "12px",
          background: "transparent",
          cursor: botTyping ? "default" : "pointer",
        }}
      >
        {botTyping ? (
          <Tooltip title="Pause">
             <PauseCircleFilled style={{ fontSize: "1.7rem", color: "#0080F9" }}/>
          </Tooltip>
        ) : (
            <Tooltip title="Send">
           <SendOutlined style={{ fontSize: "1.7rem", color: "#0080F9" }} />
         </Tooltip>
          
        )}
      </Button>
    </div>
  );
};

export default MessageInputBar;
